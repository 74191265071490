import fetchVaultBalances from '@lyra/core/api/fetchVaultBalances'
import fetchVaultStatistics from '@lyra/core/api/fetchVaultStatistics'
import fetchSubaccount from '@lyra/core/api/private/fetchSubaccount'
import { VaultStatisticsResponseSchema } from '@lyra/core/api/types/public.get_vault_statistics'
import { MAX_INT } from '@lyra/core/constants/contracts'
import { SECONDS_IN_DAY, SECONDS_IN_WEEK } from '@lyra/core/constants/time'
import formatDuration from '@lyra/core/utils/formatDuration'
import { fetchWstEthApy } from '@lyra/web/utils/tokens'
import { Address, encodeAbiParameters, toHex } from 'viem'

import superTokenAbi from '../abis/superTokenAbi'
import vaultAbi from '../abis/vaultAbi'
import {
  SOCKET_BRIDGE_L2_GAS_LIMIT,
  SOCKET_BRIDGE_YIELD_DEPOSIT_GAS_LIMIT,
  SOCKET_MESSAGE_COMPLETE_STATUSES,
} from '../constants/bridge'
import { DepositNetwork } from '../constants/chains'
import { lyraClient } from '../constants/client'
import { isMainnet } from '../constants/env'
import { InstrumentType } from '../constants/instruments'
import { MarketId } from '../constants/markets'
import { Position } from '../constants/position'
import { Subaccount } from '../constants/subaccount'
import { PartialToken } from '../constants/tokens'
import { TransactionOptions } from '../constants/transactions'
import { HELP_ENABLE_SPENDING_URL } from '../constants/urls'
import { LyraWalletClient } from '../constants/wallet'
import {
  ActiveYieldConfig,
  getEmptyYieldTokens,
  YieldBaseApyId,
  YieldBridgeTransaction,
  YieldIntegratorPointsId,
  YieldPosition,
  YieldStats,
  YieldStrategyId,
  YieldTokenConfig,
  yieldTokenConfigs,
  YieldTokenId,
  YieldTokenInputConfig,
  YieldTokenOutputConfig,
  YieldTradeHistory,
} from '../constants/yield'
import { getBridgeDurationEstimate } from './bridge'
import {
  formatDepositNetworkName,
  getChainForDepositNetwork,
  getDepositNetworkForChainId,
} from './chains'
import {
  getNextWeeklyExpiryUtc,
  parseInstrumentName,
  parseOptionFromInstrumentName,
} from './instruments'
import { getNetworkClient } from './rpc'
import { getUtcNowSecs, getUtcSecs } from './time'
import { formatBalance } from './tokens'
import { fetchScwAddress, getTransactionDisabledMessage } from './wallet'

const SENTIO_API_KEY = process.env.NEXT_PUBLIC_SENTIO_POINTS_API_KEY!

type CoveredCallsData = {
  strikePrice: number
  timeToExpiry: number
  optionType: string
  underlyingAsset: MarketId
  expiryTimestamp: number
  isExpired: boolean
  epochStartTimestamp: number
}

type SpreadStrikeData = {
  minStrikePrice: number
  maxStrikePrice: number
  timeToExpiry: number
  optionType: string
  underlyingAsset: MarketId
  expiryTimestamp: number
  isExpired: boolean
  epochStartTimestamp: number
}

const COVERED_CALLS_DATA_EMPTY: CoveredCallsData = {
  strikePrice: 0,
  isExpired: true,
  timeToExpiry: 0,
  optionType: '-',
  underlyingAsset: MarketId.ETH,
  expiryTimestamp: 0,
  epochStartTimestamp: Math.floor((Date.now() - SECONDS_IN_WEEK * 1000) / 1000),
}

const ETH_SPREAD_STRIKE_DATA_EMPTY: SpreadStrikeData = {
  minStrikePrice: 0,
  maxStrikePrice: 0,
  timeToExpiry: 0,
  optionType: '-',
  underlyingAsset: MarketId.ETH,
  expiryTimestamp: 0,
  isExpired: true,
  epochStartTimestamp: Math.floor((Date.now() - SECONDS_IN_WEEK * 1000) / 1000),
}

const BTC_SPREAD_STRIKE_DATA_EMPTY: SpreadStrikeData = {
  minStrikePrice: 0,
  maxStrikePrice: 0,
  timeToExpiry: 0,
  optionType: '-',
  underlyingAsset: MarketId.BTC,
  expiryTimestamp: 0,
  isExpired: true,
  epochStartTimestamp: Math.floor((Date.now() - SECONDS_IN_WEEK * 1000) / 1000),
}

export const getYieldTokenConfig = (id: YieldTokenId) => {
  return yieldTokenConfigs[id]
}

const tryFetchVaultBalances = async (ownerAddress: Address) => {
  try {
    return (await fetchVaultBalances({ smart_contract_owner: ownerAddress })).result
  } catch (error) {
    return []
  }
}

export const getEmptyYieldPositions = () =>
  getEmptyYieldTokens((id) => ({
    id,
    balance: 0,
    value: 0,
    balances: Object.values(DepositNetwork).reduce(
      (dict, network) => ({ ...dict, [network]: 0 }),
      {} as Record<DepositNetwork, number>
    ),
  }))

export const fetchYieldPositions = async (
  ownerAddress: Address
): Promise<Record<YieldTokenId, YieldPosition>> => {
  const [vaultBalances, allVaultStats] = await Promise.all([
    tryFetchVaultBalances(ownerAddress),
    fetchVaultStatistics(),
  ])

  const positions = vaultBalances.reduce((positions, vaultBalance) => {
    // search for yield config by input address
    const yieldConfig = Object.values(yieldTokenConfigs).find((config) => {
      return config.addresses.mintConfig.outputConfigs.some(
        (outputConfig) => outputConfig.outputToken.address === vaultBalance.address
      )
    })

    if (!yieldConfig) {
      return positions
    }

    const vaultStats = allVaultStats.result.find(
      (vault) => vault.vault_name === yieldConfig.contractTokenName
    )

    if (!vaultStats) {
      return positions
    }

    const network = getDepositNetworkForChainId(vaultBalance.chain_id)
    if (!network) {
      return positions
    }

    // balance in terms of vault token
    const balance = +vaultBalance.amount
    const value = balance * +vaultStats.usd_value

    positions[yieldConfig.id]!.balance += balance
    positions[yieldConfig.id]!.value += value
    positions[yieldConfig.id]!.balances[network] = balance

    return positions
  }, getEmptyYieldPositions())

  return positions
}

const fetchYieldBridgeFees = async (
  inputConfig: YieldTokenInputConfig,
  outputConfig: YieldTokenOutputConfig
) => {
  const depositClient = await getNetworkClient(inputConfig.network)

  const [minDepositFees, minWithdrawFees] = await Promise.all([
    depositClient.readContract({
      address: inputConfig.vault,
      abi: vaultAbi,
      functionName: 'getMinFees',
      args: [
        inputConfig.connector,
        BigInt(SOCKET_BRIDGE_YIELD_DEPOSIT_GAS_LIMIT),
        BigInt(64 + 161),
      ],
    }),
    lyraClient.readContract({
      address: outputConfig.vault,
      abi: vaultAbi,
      functionName: 'getMinFees',
      args: [outputConfig.connector, BigInt(SOCKET_BRIDGE_L2_GAS_LIMIT), BigInt(64 + 161)],
    }),
  ])

  return minDepositFees + minWithdrawFees
}

const getOptionsApy = (
  config: YieldTokenConfig,
  stats: VaultStatisticsResponseSchema,
  subaccount: Subaccount
): number => {
  switch (config.strategyId) {
    case 'CoveredCalls':
      return getCoveredCallApy(stats, subaccount)
    case 'LongPrincipalProtected':
      return getLongPrincipalProtectedApy(stats, subaccount)
    case 'CoveredPutSpread':
    case 'CoveredCallSpread':
      return getCoveredCallOrPutSpreadApy(stats, subaccount)
  }
}

const fetchBaseYieldApys = async (): Promise<Record<YieldBaseApyId, number>> => {
  return {
    ['eth-staking']: await fetchWstEthApy(),
  }
}

const fetchAllSubaccounts = async (): Promise<Partial<Record<YieldTokenId, Subaccount>>> => {
  const yieldConfigs = Object.values(yieldTokenConfigs)
  const subaccounts = await Promise.all(
    yieldConfigs.map(async (config) => {
      if (!config.isEarlyDeposit) {
        const { result } = await fetchSubaccount(
          { subaccount_id: config.subaccountId },
          config.authHeaders
        )
        return result
      } else {
        return null
      }
    })
  )

  return yieldConfigs.reduce(
    (dict, config, idx) => ({
      ...dict,
      [config.id]: subaccounts[idx] ?? undefined,
    }),
    {} as Partial<Record<YieldTokenId, Subaccount>>
  )
}

export const fetchYieldStats = async (): Promise<Record<YieldTokenId, YieldStats>> => {
  const [allVaultStats, allSubaccounts, baseApys] = await Promise.all([
    fetchVaultStatistics(),
    fetchAllSubaccounts(),
    fetchBaseYieldApys(),
  ])

  return Object.values(yieldTokenConfigs).reduce(
    (dict, config) => {
      const vaultStats = allVaultStats.result.find(
        (vault) => vault.vault_name === config.contractTokenName
      )

      if (!vaultStats) {
        console.warn('missing vault_statistics for', config.id)
        return dict
      }

      const baseValue = +vaultStats.base_value
      const withdrawRate = config.isEarlyDeposit ? 1 : baseValue
      const depositRate = withdrawRate !== 0 ? 1 / withdrawRate : 1

      const baseApy = config.baseApy ? baseApys[config.baseApy] : 0
      const subaccount = allSubaccounts[config.id] ?? null

      const optionsApy = subaccount ? getOptionsApy(config, vaultStats, subaccount) : 0

      const totalSupply = +vaultStats.total_supply
      const tvl = +vaultStats.usd_tvl

      const stats: YieldStats = {
        id: config.id,
        totalSupply,
        baseApy,
        optionsApy,
        depositRate,
        withdrawRate,
        tvl,
        subaccount,
      }

      return {
        ...dict,
        [config.id]: stats,
      }
    },
    getEmptyYieldTokens((id) => ({
      id,
      totalSupply: 0,
      baseApy: 0,
      optionsApy: 0,
      depositRate: 0,
      withdrawRate: 0,
      tvl: 0,
      subaccount: null,
    }))
  )
}

const fetchYieldCollateralBalance = async (
  inputConfig: YieldTokenInputConfig,
  ownerAddress: Address
): Promise<bigint> => {
  const client = await getNetworkClient(inputConfig.network)
  const balance = await client.readContract({
    address: inputConfig.inputToken.address,
    abi: superTokenAbi,
    functionName: 'balanceOf',
    args: [ownerAddress],
  })
  return balance
}

export const fetchYieldQuote = async (
  inputConfig: YieldTokenInputConfig,
  outputConfig: YieldTokenOutputConfig,
  ownerAddress: Address | null
) => {
  const [balance, minFees] = await Promise.all([
    // TODO: @earthtojake replae with useBalances
    ownerAddress ? fetchYieldCollateralBalance(inputConfig, ownerAddress) : BigInt(0),
    fetchYieldBridgeFees(inputConfig, outputConfig),
  ])

  return { balance, minFees }
}

const screenMint = async (amount: bigint, network: DepositNetwork, token: PartialToken) => {
  const screenRes = await fetch('/api/yield/screen', {
    method: 'POST',
    body: JSON.stringify({
      amount: toHex(amount),
      network,
      symbol: token.symbol,
      decimals: token.decimals,
    }),
  })

  if (!screenRes.ok) {
    if (screenRes.status === 500) {
      throw new Error(getTransactionDisabledMessage('kyt-failed'))
    } else if (screenRes.status === 403) {
      throw new Error(getTransactionDisabledMessage('kyt'))
    } else {
      throw new Error('Wallet screening failed')
    }
  }
}

export const mintOrRedeemYieldTokenImpl = async (
  walletClient: LyraWalletClient,
  amount: bigint,
  isMint: boolean, // or redeem
  config: YieldTokenConfig,
  inputConfig: YieldTokenInputConfig,
  outputConfig: YieldTokenOutputConfig,
  options: TransactionOptions
) => {
  if (amount === BigInt(0)) {
    throw new Error('Zero amount')
  }
  if (walletClient.chain.id !== getChainForDepositNetwork(inputConfig.network).id) {
    throw new Error(`Not connected to ${inputConfig.network}`)
  }

  console.debug(isMint ? 'deposit' : 'withdraw', amount, outputConfig.outputToken.symbol)

  if (isMainnet && isMint) {
    console.debug('screen wallet address')
    await screenMint(amount, inputConfig.network, inputConfig.inputToken)
  }

  const ownerAddress = walletClient.account.address
  const [depositClient, scwAddress] = await Promise.all([
    getNetworkClient(inputConfig.network),
    fetchScwAddress(ownerAddress),
  ])

  const balance = await depositClient.readContract({
    address: inputConfig.inputToken.address,
    abi: superTokenAbi,
    functionName: 'balanceOf',
    args: [ownerAddress],
  })

  if (balance < amount) {
    throw new Error(`Insufficient ${inputConfig.inputToken.symbol} balance`)
  }

  const allowance = await depositClient.readContract({
    address: inputConfig.inputToken.address,
    abi: superTokenAbi,
    functionName: 'allowance',
    args: [ownerAddress, inputConfig.vault],
  })

  console.debug('allowance', allowance)

  if (allowance < amount) {
    options.onTransactionStatusChange('confirm', {
      title: `Enable Spending ${inputConfig.inputToken.symbol} on Derive`,
      contextLink: {
        href: HELP_ENABLE_SPENDING_URL,
        label: 'Why is this required?',
        target: '_blank',
      },
    })

    // approve vault
    console.log('approve vault')
    const approveHash = await walletClient.writeContract({
      address: inputConfig.inputToken.address,
      abi: superTokenAbi,
      functionName: 'approve',
      args: [inputConfig.vault, MAX_INT],
    })

    options.onTransactionStatusChange('in-progress', {
      title: `Enabling Spending ${inputConfig.inputToken.symbol} on Derive`,
    })

    const approveReceipt = await depositClient.waitForTransactionReceipt({ hash: approveHash })
    if (approveReceipt.status === 'reverted') {
      throw new Error('Approval reverted')
    }

    console.debug('approval receipt', approveReceipt)

    const newAllowance = await depositClient.readContract({
      address: inputConfig.inputToken.address,
      abi: superTokenAbi,
      functionName: 'allowance',
      args: [ownerAddress, inputConfig.vault],
    })

    console.debug('new allowance', newAllowance)

    if (newAllowance < amount) {
      throw new Error('Insufficient allowance')
    }
  }

  const minFees = await fetchYieldBridgeFees(inputConfig, outputConfig)

  options.onTransactionStatusChange('confirm', {
    title: `${isMint ? 'Deposit' : 'Withdraw'} ${formatBalance(amount, inputConfig.inputToken, {
      showFullBalance: true,
    })}`,
  })

  const hash = await walletClient.writeContract({
    address: inputConfig.vault,
    abi: vaultAbi,
    functionName: 'bridge',
    args: [
      // SUPER IMPORTANT!! must be smart contract wallet address
      scwAddress,
      amount,
      BigInt(SOCKET_BRIDGE_YIELD_DEPOSIT_GAS_LIMIT),
      inputConfig.connector,
      encodeAbiParameters(
        [
          { name: 'address', type: 'address' },
          { name: 'address', type: 'address' },
        ],
        [ownerAddress, outputConfig.connector]
      ),
      '0x',
    ],
    value: minFees,
  })

  options.onTransactionStatusChange('in-progress', {
    title: `${isMint ? 'Depositing' : 'Withdrawing'} ${formatBalance(
      amount,
      inputConfig.inputToken,
      {
        showFullBalance: true,
      }
    )}`,
  })

  const receipt = await depositClient.waitForTransactionReceipt({ hash })
  if (receipt.status === 'reverted') {
    throw new Error('Transaction reverted')
  }

  const apiParams = {
    amount: toHex(amount),
    network: inputConfig.network,
    tokenAddress: inputConfig.inputToken.address,
    strategy: config.strategyId,
    transactionHash: hash,
    isMint,
  }

  // Save transaction for status tracking
  await fetch('/api/yield/bridge', {
    method: 'POST',
    body: JSON.stringify(apiParams),
  })

  if (!options.skipCompleteStatus) {
    // Redeem delay if vault is executing
    if (!isMint && !config.isEarlyDeposit) {
      const nextFridayExpirySecs = getUtcSecs(getNextWeeklyExpiryUtc())
      const nowSecs = getUtcNowSecs()
      const durationSecs = nextFridayExpirySecs - nowSecs

      options.onTransactionStatusChange('complete', {
        title: `Requested ${formatBalance(amount, outputConfig.outputToken, {
          showFullBalance: true,
        })} Withdrawal`,
        txHash: hash,
        chain: getChainForDepositNetwork(inputConfig.network),
        context: `Your ${
          outputConfig.outputToken.symbol
        } will be automatically withdrawn to your wallet on ${formatDepositNetworkName(
          inputConfig.network
        )} after the withdrawal period of ${formatDuration(durationSecs, {
          showSeconds: false,
        })}`,
      })
    } else {
      options.onTransactionStatusChange('complete', {
        title: `Successfully ${isMint ? 'Minted' : 'Redeemed'} ${formatBalance(
          amount,
          outputConfig.outputToken,
          { showFullBalance: true }
        )}`,
        txHash: hash,
        chain: getChainForDepositNetwork(inputConfig.network),
        context: `Your ${
          outputConfig.outputToken.symbol
        } will be available in your wallet on ${formatDepositNetworkName(
          inputConfig.network
        )} in ${getBridgeDurationEstimate(inputConfig.network)}.`,
      })
    }
  }

  console.debug('tx hash', hash)

  return hash
}

export const getYieldTokenConfigForInputToken = (
  inputTokenAddress: Address,
  strategy: YieldStrategyId
) => {
  return Object.values(yieldTokenConfigs).find(
    (config) =>
      config.addresses.mintConfig.inputConfigs.some(
        (inputConfig) =>
          inputConfig.inputToken.address === inputTokenAddress &&
          (!strategy || (strategy && config.strategyId === strategy))
      ) ||
      config.addresses.redeemConfig.inputConfigs.some(
        (inputConfig) =>
          inputConfig.inputToken.address === inputTokenAddress &&
          (!strategy || (strategy && config.strategyId === strategy))
      )
  )
}

const getCoveredCallWeeklyPerformance = (
  amount: string,
  avgWithoutFees: string,
  collateralValue: string
): number => {
  const amountNumber = +amount
  const avgWithoutFeesNumber = +avgWithoutFees
  const collateralValueNumber = +collateralValue
  const premiums = avgWithoutFeesNumber * Math.abs(amountNumber)
  const performancePercentage = premiums / collateralValueNumber
  return performancePercentage
}

const getCoveredCallOrPutSpreadWeeklyPerformance = (
  positions: Position[],
  collateralValue: string
): number => {
  if (positions.length < 2) return 0

  const [position1, position2] = positions
  const positionOneIsLong = +position1.amount > 0
  const amount1 = +position1.amount
  const avgPrice1 = +position1.average_price
  const amount2 = +position2.amount
  const avgPrice2 = +position2.average_price

  const collateralValueNumber = +collateralValue

  const premiums1 = avgPrice1 * Math.abs(amount1)
  const premiums2 = avgPrice2 * Math.abs(amount2)

  const performance1 = premiums1 / collateralValueNumber
  const performance2 = premiums2 / collateralValueNumber

  return positionOneIsLong ? performance2 - performance1 : performance1 - performance2
}

export const getLongPrincipalProtectedWeeklyPerformance = (
  spotPrice: number,
  positions: Position[],
  collateralValue: number
) => {
  if (positions.length !== 2) {
    return 0
  }

  const parsedPositionOne = parseOptionFromInstrumentName(positions[0].instrument_name)
  const parsedPositionTwo = parseOptionFromInstrumentName(positions[1].instrument_name)

  if (!parsedPositionOne || !parsedPositionTwo) {
    return 0
  }

  const minParsedPosition =
    parsedPositionOne.strikePrice < parsedPositionTwo.strikePrice
      ? parsedPositionOne
      : parsedPositionTwo

  const maxParsedPosition =
    parsedPositionOne.strikePrice > parsedPositionTwo.strikePrice
      ? parsedPositionOne
      : parsedPositionTwo

  const minPositionSize =
    parsedPositionOne.strikePrice < parsedPositionTwo.strikePrice
      ? +positions[0].amount
      : +positions[1].amount

  const maxPositionSize =
    parsedPositionOne.strikePrice > parsedPositionTwo.strikePrice
      ? +positions[0].amount
      : +positions[1].amount

  return (
    (Math.max(spotPrice - minParsedPosition.strikePrice, 0) * minPositionSize +
      Math.max(spotPrice - maxParsedPosition.strikePrice, 0) * maxPositionSize) /
    collateralValue
  )
}

const getCoveredCallApy = (stats: VaultStatisticsResponseSchema, subaccount: Subaccount) => {
  const { positions } = subaccount

  if (!positions.length) {
    return 0
  }

  const parsedInstrument = parseInstrumentName(positions[0].instrument_name)
  if (!parsedInstrument || parsedInstrument.type !== InstrumentType.Options) {
    return 0
  }

  const amount = positions[0].amount
  const avgWithoutFees = positions[0].average_price_excl_fees
  const subaccountValue = stats.subaccount_value_at_last_trade
  const spotPrice = +positions[0].index_price
  const strikePrice = parsedInstrument.strikePrice

  if (!subaccountValue) {
    return 0
  }

  if (spotPrice > strikePrice) {
    return 0
  }

  const performance = getCoveredCallWeeklyPerformance(amount, avgWithoutFees, subaccountValue)
  return performance * 52
}

const getCoveredCallOrPutSpreadApy = (
  stats: VaultStatisticsResponseSchema,
  subaccount: Subaccount
) => {
  const { positions } = subaccount

  if (!positions.length) {
    return 0
  }

  const subaccountValue = stats.subaccount_value_at_last_trade
  if (!subaccountValue) {
    return 0
  }

  const performance = getCoveredCallOrPutSpreadWeeklyPerformance(positions, subaccountValue)

  return performance * 52
}

export const parseCoveredCallVaultTradeHistory = (
  tradeHistory: YieldTradeHistory
): CoveredCallsData => {
  if (!tradeHistory.trades.length) {
    return COVERED_CALLS_DATA_EMPTY
  }

  const latestTrade = tradeHistory.trades.sort(
    (a, b) =>
      (parseOptionFromInstrumentName(b.instrument_name)?.expiry.getTime() ?? 0) -
      (parseOptionFromInstrumentName(a.instrument_name)?.expiry.getTime() ?? 0)
  )[0]

  const parsedOption = parseOptionFromInstrumentName(latestTrade.instrument_name)

  if (!parsedOption) {
    return COVERED_CALLS_DATA_EMPTY
  }

  const now = new Date()
  return {
    strikePrice: parsedOption.strikePrice,
    timeToExpiry: now.getTime() - parsedOption.expiry.getTime(),
    isExpired: parsedOption.expiry < now,
    optionType: parsedOption.isCall ? 'Call' : 'Put',
    underlyingAsset: parsedOption.marketId,
    expiryTimestamp: parsedOption.expiry.getTime(),
    // Start timestamp is the last trade, floored to 1 week ago
    epochStartTimestamp: Math.floor(
      Math.max(latestTrade.timestamp, now.getTime() - SECONDS_IN_WEEK * 1000) / 1000
    ),
  }
}

const getLongPrincipalProtectedApy = (
  stats: VaultStatisticsResponseSchema,
  subaccount: Subaccount
) => {
  const { positions } = subaccount

  if (positions.length !== 2) {
    return 0
  }

  const spotPrice = +positions[0].index_price
  const subaccountValue = stats.subaccount_value_at_last_trade

  if (!subaccountValue) {
    return 0
  }

  return getLongPrincipalProtectedWeeklyPerformance(spotPrice, positions, +subaccountValue) * 52
}

export const parseStrikeSpreadData = (
  positions: Position[],
  tradeHistory: YieldTradeHistory,
  config: YieldTokenConfig
): SpreadStrikeData => {
  const emptyData = config.collateralId.includes('BTC')
    ? BTC_SPREAD_STRIKE_DATA_EMPTY
    : ETH_SPREAD_STRIKE_DATA_EMPTY

  if (positions.length !== 2 || !tradeHistory.trades.length) {
    return emptyData
  }

  const parsedPositionOne = parseOptionFromInstrumentName(positions[0].instrument_name)
  const parsedPositionTwo = parseOptionFromInstrumentName(positions[1].instrument_name)

  const latestTrade = tradeHistory.trades.sort(
    (a, b) =>
      (parseOptionFromInstrumentName(b.instrument_name)?.expiry.getTime() ?? 0) -
      (parseOptionFromInstrumentName(a.instrument_name)?.expiry.getTime() ?? 0)
  )[0]

  const parsedTrade = parseOptionFromInstrumentName(latestTrade.instrument_name)

  if (!parsedPositionOne || !parsedPositionTwo || !parsedTrade) {
    return emptyData
  }

  const minPosition =
    parsedPositionOne.strikePrice < parsedPositionTwo.strikePrice
      ? parsedPositionOne
      : parsedPositionTwo

  const maxPosition =
    parsedPositionOne.strikePrice > parsedPositionTwo.strikePrice
      ? parsedPositionOne
      : parsedPositionTwo

  const now = new Date()

  const result: SpreadStrikeData = {
    minStrikePrice: minPosition.strikePrice,
    maxStrikePrice: maxPosition.strikePrice,
    timeToExpiry: now.getTime() - minPosition.expiry.getTime(),
    isExpired: minPosition.expiry < now,
    optionType: minPosition.isCall ? 'Call' : 'Put',
    underlyingAsset: minPosition.marketId,
    expiryTimestamp: parsedTrade.expiry.getTime(),
    epochStartTimestamp: Math.floor(
      Math.max(latestTrade.timestamp, now.getTime() - SECONDS_IN_WEEK * 1000) / 1000
    ),
  }

  if (config.collateralId.includes('BTC')) {
    result.underlyingAsset = MarketId.BTC
  }

  return result
}

export const getSpreadStrikeDifference = (positions: Position[]): number | undefined => {
  if (positions.length !== 2) {
    return undefined
  }

  const parsedPositionOne = parseOptionFromInstrumentName(positions[0].instrument_name)
  const parsedPositionTwo = parseOptionFromInstrumentName(positions[1].instrument_name)

  if (!parsedPositionOne || !parsedPositionTwo) {
    return undefined
  }

  const difference = Math.abs(parsedPositionOne.strikePrice - parsedPositionTwo.strikePrice)

  return difference
}

export const getYieldBridgeStatus = (tx: YieldBridgeTransaction) => {
  if (tx.fromStatus.bridgeType !== 'socket' || tx.toStatus.bridgeType !== 'socket') {
    throw new Error('Wrong yield bridge type')
  }

  const isToPending = !SOCKET_MESSAGE_COMPLETE_STATUSES.includes(tx.toStatus.status)
  const isFromPending = !SOCKET_MESSAGE_COMPLETE_STATUSES.includes(tx.fromStatus.status)

  if (tx.toStatus.status === 'EXECUTION_FAILURE') {
    return {
      status: 'retrying',
      message: 'Transaction reverted on-chain. Retrying transaction',
    }
  }
  if (tx.fromStatus.status === 'EXECUTION_FAILURE') {
    return {
      status: 'retrying',
      // TODO @michaelxuwu recovery message
      message: 'Transaction reverted on-chain. Retrying transaction',
    }
  }

  if (isToPending) {
    return {
      status: 'pending',
      message: 'Bridge transaction to Lyra is in progress',
    }
  }

  if (isFromPending && tx.type === 'withdraw-yield') {
    return {
      status: 'requested withdrawal',
      message: 'Withdrawal has been requested and will be processed at the end of the epoch',
    }
  } else if (isFromPending) {
    return {
      status: 'pending',
      message: 'Bridge transaction from Lyra is in progress',
    }
  }

  return { status: 'completed' }
}

export const getSortedYieldTokenList = (
  stats: Record<YieldTokenId, YieldStats>,
  positions: Partial<Record<YieldTokenId, YieldPosition>>
) => {
  // start with default sorting
  return (
    Object.values(YieldTokenId)
      // filter out unlisted (unless user has position)
      .filter(
        (yieldId) =>
          !getYieldTokenConfig(yieldId).isUnlisted ||
          (positions[yieldId] && positions[yieldId]!.value > 0)
      )
      .map((yieldId) => {
        const balanceValue = positions[yieldId]?.value ?? 0
        const tvl = stats[yieldId].tvl
        const isFeatured = getYieldTokenConfig(yieldId).isFeatured
        return {
          id: yieldId,
          balanceValue,
          tvl,
          featured: isFeatured ? 1 : 0,
        }
      })
      .sort((a, b) => {
        // vaults with balances go next
        const balanceDiff = b.balanceValue - a.balanceValue
        if (balanceDiff) {
          return balanceDiff
        }
        // featured vaults go first
        const featuredDiff = b.featured - a.featured
        if (featuredDiff) {
          return featuredDiff
        }
        // vaults with largest tvl go last
        return b.tvl - a.tvl
      })
      .map((s) => s.id)
  )
}

export function getActiveYieldConfigs(): ActiveYieldConfig[] {
  return Object.values(yieldTokenConfigs).filter(
    (config): config is ActiveYieldConfig => !config.isEarlyDeposit
  )
}

const fetchNetworkYieldTvlAtTimestamp = async (
  config: YieldTokenConfig,
  outputConfig: YieldTokenOutputConfig,
  timestamp: number
) => {
  const integrator = config.integratorPointsId
  const startTimestampMs = timestamp - SECONDS_IN_DAY * 1000

  const assetQuery = getYieldAssetQuery(config, outputConfig.network)
  const effectiveBalanceColumn = getYieldBalanceColumn(integrator)
  const query = `
          SELECT SUM(${effectiveBalanceColumn}) as tvl
          FROM point_update
          WHERE ${assetQuery}
          AND newTimestampMs BETWEEN ${startTimestampMs} AND ${timestamp};
        `

  try {
    const response = await fetch(
      `https://app.sentio.xyz/api/v1/analytics/derive/${integrator}-integration/sql/execute`,
      {
        method: 'POST',
        body: JSON.stringify({
          sqlQuery: {
            sql: query,
            size: 10000,
          },
        }),
        headers: {
          'api-key': SENTIO_API_KEY,
          'Content-Type': 'application/json',
        },
      }
    )
    const res = await response.json()
    return +res.result.rows[0].tvl
  } catch (error) {
    console.error(error)
    throw new Error('Failed to fetch Derive balances')
  }
}

export const fetchYieldTvlAtTimestamp = async (config: YieldTokenConfig, timestamp: number) => {
  let tvl = 0
  for (const outputConfig of config.addresses.mintConfig.outputConfigs) {
    tvl += await fetchNetworkYieldTvlAtTimestamp(config, outputConfig, timestamp)
  }
  return tvl
}

const formatYieldSentioId = (network: DepositNetwork, yieldTokenId: YieldTokenId) => {
  switch (network) {
    case DepositNetwork.Ethereum:
      return `${yieldTokenId}_MAINNET`
    case DepositNetwork.Arbitrum:
      return `${yieldTokenId}_ARB`
    case DepositNetwork.Base:
      return `${yieldTokenId}_BASE`
    case DepositNetwork.Optimism:
      return `${yieldTokenId}_OP`
  }
}

export const getYieldAssetQuery = (config: YieldTokenConfig, depositNetwork: DepositNetwork) => {
  const assetName = formatYieldSentioId(depositNetwork, config.id)
  const integrator = config.integratorPointsId
  const outputConfig = config.addresses.mintConfig.outputConfigs.find(
    (outputConfig) => outputConfig.network === depositNetwork
  )

  if (!outputConfig) {
    throw new Error(`No matching vault address for network: ${config.id} - ${depositNetwork}`)
  }

  return `  ${
    integrator === 'lombard'
      ? `vaultAddress = '${outputConfig.outputToken.address}'`
      : `assetName = '${assetName}'`
  }`
}

export const getYieldBalanceColumn = (integrator: YieldIntegratorPointsId) =>
  integrator === 'lombard' ? 'newUnderlyingEffectiveBalance' : 'newEffectiveBalance'
